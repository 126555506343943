
import { defineComponent, computed, ref } from 'vue'
import Button from 'primevue/button'
import { PrimeIcons } from 'primevue/api'
import InputText from 'primevue/inputtext'
import { JobBankRouteNames } from '@/router/route-names'
import { useRoute } from 'vue-router'

export default defineComponent({
  Components: {
    Button,
    PrimeIcons,
    InputText,
    ref
  },
  setup () {
    const route = useRoute()
    const currentRoute = computed(() => {
      return route.name
    })

    const filter = ref([])
    const clear = () => {
      filter.value = []
    }
    return {
      filter,
      clear,
      JobBankRouteNames,
      currentRoute
    }
  }
})
